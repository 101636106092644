import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import {
	Input,
	Select,
} from '../../../../../../../_metronic/_partials/controls'
import _ from 'lodash'
import { Button } from 'react-bootstrap'

import * as actions from '../../../../_redux/categoryCourse/categoryCourseAction'

import { AVAILABLE_LEVEL } from '../QuizUIHelper'

export function QuizInformationForm({ quizData, saveQuizInfo, type }) {
	const schema_fields = {
		quizName: Yup.string()
			.min(2, 'Minimum 2 symbols')
			.max(500, 'Maximum 500 symbols')
			.required('Quiz name is required'),
		quizDescription: Yup.string()
			.min(2, 'Minimum 2 symbols')
			.max(500, 'Maximum 500 symbols')
			.required('Quiz description is required'),
		// level: Yup.string().required("Category is required")
	}
	if (type === 'question' && !quizData.id)
		schema_fields.totalQuestion = Yup.number()
			.min(1, '1 is minimum')
			.max(100, '100 is maximum')
			.required('Number of question is required')
	const QuizAddSchema = Yup.object().shape(schema_fields)
	const intl = useIntl()
	const dispatch = useDispatch()

	const { entities } = useSelector(
		(state) => ({
			entities: state.categoryCourse.entities,
		}),
		shallowEqual,
	)
	useEffect(() => {
		const queryParams = {
			filter: {
				category: '',
			},
			sortOrder: 'asc', // asc||desc
			sortField: 'category',
			pageNumber: 1,
			pageSize: 100,
		}
		dispatch(actions.fetchAllCategoryCourse(queryParams))
	}, [dispatch])

	const [courList, setCourList] = useState({ data: [] })

	const onCategoryChange = (value, cource_id = null) => {
		const obj = entities.find((c) => c.category === value)
		if (obj) {
			setCourList({ data: obj.course })
			if (cource_id) setCour(cource_id)
		} else {
			setCourList({ data: [] })
			setCour('')
		}
	}

	useEffect(() => {
		if (quizData && entities) {
			setCat(quizData.category)
			onCategoryChange(quizData.category)
			setCour(quizData.course)
		}
	}, [quizData, entities])
	const [custom_errors, setCustomErrors] = useState([])

	const [cat, setCat] = useState('')
	const [cour, setCour] = useState('')
	const [alertStatus, setAlertStatus] = useState(false)

	const MyTextArea = ({ label, ...props }) => {
		// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
		// which we can spread on <input> and alse replace ErrorMessage entirely.
		const [field, meta] = useField(props)
		return (
			<>
				<label htmlFor={props.id || props.name}>{label}</label>
				<textarea
					className={`text-area form-control ${
						meta.touched && meta.error ? 'is-invalid' : ''
					}`}
					{...field}
					{...props}
				/>
				{meta.touched && meta.error ? (
					<div className='invalid-feedback'>{meta.error}</div>
				) : null}
			</>
		)
	}

	useEffect(() => {
		if (entities) {
			setCat(quizData.category)
			onCategoryChange(quizData.category, quizData.course)
		}
	}, [entities])

	return (
		<Fragment>
			<Formik
				enableReinitialize={true}
				initialValues={quizData}
				onSubmit={(values) => {
					setCustomErrors([])
					if (cat !== '' && cour !== '' && values.level !== '') {
						values.category = cat
						values.course = cour
						saveQuizInfo(values)
					} else {
						let c_errors = _.cloneDeep(custom_errors)

						if (cat === '') {
							c_errors.push('Category is required')
						}

						if (cour === '') {
							c_errors.push('Course is required')
						}

						setCustomErrors(c_errors)

						setAlertStatus(true)
						setTimeout(() => {
							setAlertStatus(false)
						}, 4000)
					}
				}}
				validationSchema={QuizAddSchema}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<>
						<Form className='form form-label-right'>
							{alertStatus && (
								<Alert
									severity='error'
									onClose={() => {
										setAlertStatus(false)
									}}
									className='mb-4'
								>
									{custom_errors.map((e, index) => {
										return (
											<span key={index}>
												{e}
												<br />
											</span>
										)
									})}
								</Alert>
							)}

							<div className='form-group row'>
								<div className='col-lg-6'>
									<Field
										name='quizName'
										component={Input}
										placeholder={intl.formatMessage({ id: 'QUIZ_NAME' })}
										label={<FormattedMessage id='QUIZ_NAME'></FormattedMessage>}
									/>
								</div>
								<div className='col-lg-6'>
									{/* <Field
                                            name="quizDescription"
                                            component={Input}
                                            placeholder={intl.formatMessage({ id: "QUIZ_DESCRIPTION" })}
                                            label={<FormattedMessage id="QUIZ_DESCRIPTION"></FormattedMessage>}
                                        /> */}
									<MyTextArea
										// style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
										label={
											<FormattedMessage id='QUIZ_DESCRIPTION'></FormattedMessage>
										}
										name='quizDescription'
										rows='2'
										placeholder={intl.formatMessage({
											id: 'QUIZ_DESCRIPTION',
										})}
									/>
								</div>
							</div>
							<div className='form-group row'>
								<div className='col-lg-6'>
									<Select
										disabled={quizData.takenQuizes?.length ? true : false}
										name='category'
										label={<FormattedMessage id='CATEGORY'></FormattedMessage>}
										value={cat}
										onChange={(e) => {
											onCategoryChange(e.target.value)
											setCat(e.target.value)
											setCour('')
										}}
									>
										<option value=''>
											{intl.formatMessage({ id: 'SELECT_ONE' })}
										</option>
										{entities &&
											entities.map((e) => (
												<option key={e.id} value={e.category}>
													{e.category}
												</option>
											))}
									</Select>
								</div>
								<div className='col-lg-6'>
									<Select
										disabled={quizData.takenQuizes?.length ? true : false}
										name='course'
										label={<FormattedMessage id='COURSE'></FormattedMessage>}
										value={cour}
										onChange={(e) => setCour(e.target.value)}
									>
										<option value=''>
											{intl.formatMessage({ id: 'SELECT_ONE' })}
										</option>
										{courList.data.map((e) => (
											<option key={e} value={e}>
												{e}
											</option>
										))}
									</Select>
								</div>
							</div>
							<div className='form-group row'>
								<div className='col-lg-6'>
									<Select
										name='level'
										label={<FormattedMessage id='LEVEL'></FormattedMessage>}
									>
										<option value=''>
											{intl.formatMessage({ id: 'SELECT_ONE' })}
										</option>
										{AVAILABLE_LEVEL.map((a) => (
											<option key={a} value={a}>
												{a}
											</option>
										))}
									</Select>
								</div>
								{type === 'question' && !quizData.id && (
									<div className='col-lg-6'>
										<Field
											type='number'
											name='totalQuestion'
											component={Input}
											placeholder={intl.formatMessage({ id: 'TOTAL_QUESTION' })}
											label={
												<FormattedMessage id='TOTAL_QUESTION'></FormattedMessage>
											}
										/>
									</div>
								)}
								<div className='form-group row'>
									<label className='col-12 col-form-label'>Premium Quiz</label>
									<div className='col-3'>
										<span className='switch switch-icon'>
											<label>
												<input
													type='checkbox'
													checked={values.isPremium}
													name='isPremium'
													onChange={(e) =>
														setFieldValue('isPremium', !values.isPremium)
													}
												/>
												<span></span>
											</label>
										</span>
									</div>
								</div>
							</div>
							<Button
								className='d-none'
								type='submit'
								variant='primary'
								onSubmit={handleSubmit}
								id='check2'
							>
								<FormattedMessage id='NEXT'></FormattedMessage>
							</Button>
						</Form>
					</>
				)}
			</Formik>
		</Fragment>
	)
}
