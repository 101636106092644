import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../_metronic/_partials/controls'
import { Button } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'

import QuestionList from './question-list/QuestionList'
import { QuestionCategoryCourseForm } from './question-category-course-form/QuestionCategoryCourseForm'

const QuestionCard = ({ categoryprop, courseprop, history }) => {
	const [place, setPlace] = useState(categoryprop && courseprop ? 1 : 0)
	const nextPlace = () => setPlace(place + 1)
	const prevPlace = () => setPlace(place - 1)

	const [formData, setFormData] = useState({
		category: categoryprop,
		course: courseprop,
	})

	const { category, course } = formData

	const saveFormData = (cat, cour) => {
		setFormData({ category: cat, course: cour })
		nextPlace()
	}

	const goToNewQuestion = () => {
		history.push(`/teacher/question/${category}/${course}/new`)
	}
	return (
		<Fragment>
			<Card>
				{place === 0 ? (
					<Fragment>
						<CardHeader
							title={<FormattedMessage id='NAV.QUESTIONS'></FormattedMessage>}
						>
							<CardHeaderToolbar>
								<Button
									variant='primary'
									size='sm'
									className='ml-3'
									onClick={() =>
										history.push('/teacher/questions/newCatCourse')
									}
								>
									<span className='svg-icon svg-icon-md svg-icon-white'>
										<SVG
											src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
										/>
									</span>
									<FormattedMessage id='TEACHER.CREATE_CATEGORY_AND_COURSE'></FormattedMessage>
								</Button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<div style={style.root}>
								<QuestionCategoryCourseForm
									formData={formData}
									saveFormData={saveFormData}
								/>
							</div>
						</CardBody>
					</Fragment>
				) : place === 1 ? (
					<Fragment>
						<CardHeader
							title={`Question in Category >> ${category}, Course >> ${course}`}
						>
							<CardHeaderToolbar>
								<Button
									variant='secondary'
									size='sm'
									onClick={() => prevPlace()}
								>
									<span className='svg-icon svg-icon-md svg-icon-dark'>
										<SVG
											src={toAbsoluteUrl(
												'/media/svg/icons/Navigation/Arrow-left.svg',
											)}
										/>
									</span>
									<FormattedMessage id='GO_BACK'></FormattedMessage>
								</Button>
								<Button
									variant='primary'
									size='sm'
									className='ml-3'
									onClick={() => goToNewQuestion()}
								>
									<span className='svg-icon svg-icon-md svg-icon-white'>
										<SVG
											src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
										/>
									</span>
									<FormattedMessage id='CREATE_QUESTION'></FormattedMessage>
								</Button>
								<Button
									variant='primary'
									size='sm'
									className='ml-3'
									onClick={() => history.push('/teacher/questions/uploadCSV')}
								>
									<span className='svg-icon svg-icon-md svg-icon-white'>
										<SVG
											src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
										/>
									</span>
									Upload CSV
									{/* <FormattedMessage id='TEACHER.CREATE_CATEGORY_AND_COURSE'></FormattedMessage> */}
								</Button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<QuestionList category={category} course={course} />
						</CardBody>
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}
			</Card>
		</Fragment>
	)
}

const style = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 350,
		margin: '20px auto',
	},
}

export default QuestionCard
