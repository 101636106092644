import React, { useState, useEffect, useCallback, useRef } from 'react'
import { fetchTags } from '../../../../_redux/tags/tagsAction'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'

/////////////////////////////////////////////////

// Tagify settings object
const baseTagifySettings = {
	blacklist: [],
	maxTags: 6,
	//backspace: "edit",
	placeholder: 'search by question categories',
	dropdown: {
		enabled: 0, // a;ways show suggestions dropdown
	},
}

// this is an example React component which implemenets Tagify within
// itself. This example is a bit elaborate, to demonstrate what's possible.
const CrazyTags = ({ getTags }) => {
	const tagifyRef = useRef()
	const [tagifyProps, setTagifyProps] = useState({})
	const dispatch = useDispatch()
	// const layoutDispatch = useContext(LayoutContext.Dispatch);
	const { tags } = useSelector(
		(state) => ({
			tags: state.tags.entities,
		}),
		shallowEqual,
	)

	useEffect(() => {
		dispatch(fetchTags())
	}, [dispatch])

	// on component mount
	useEffect(() => {
		setTagifyProps((lastProps) => ({
			...lastProps,
			showFilteredDropdown: 'a',
			loading: false,
		}))
	}, [])

	useEffect(() => {
		const tag_names = tags ? tags.map((tag) => tag.name) : []
		setTagifyProps((lastProps) => ({
			...lastProps,
			whitelist: tag_names,
		}))
	}, [tags])

	// merged tagify settings (static & dynamic)
	const settings = {
		...baseTagifySettings,
	}

	const onChange = useCallback((e) => {
		getTags(e.detail.value)
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Tags
				className='m-2 rounded-sm'
				tagifyRef={tagifyRef}
				settings={settings}
				autoFocus={true}
				{...tagifyProps}
				onChange={onChange}
			/>
		</>
	)
}

export default CrazyTags
