/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { FormattedMessage } from 'react-intl'

export function AsideMenuList({ layoutProps }) {
	const location = useLocation()
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url)
			? ' menu-item-active menu-item-open '
			: ''
	}

	const { userType, profile } = useSelector(
		(state) => ({
			userType: state.auth.user.userType,
			profile: state.auth.user.profile,
		}),
		shallowEqual,
	)

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{userType === 'Student' ? (
					<Fragment>
						{/*begin::1 Level*/}
						{/* <li
                className={`menu-item ${getMenuItemActive("/student/dashboard")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/student/dashboard">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                  </span>
                  <span className="menu-text">Dashboard</span>
                </NavLink>
              </li> */}
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/student/quizPool')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/student/quizPool'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Layout/Layout-4-blocks.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/student/takenQuiz')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/student/takenQuiz'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Shopping/Barcode-read.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.TAKEN_QUIZ'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
					</Fragment>
				) : userType === 'Teacher' ? (
					<Fragment>
						{/*begin::1 Level*/}
						{/* <li className={`menu-item ${getMenuItemActive('/teacher/dashboard')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/teacher/dashboard'>
                                <span className='svg-icon menu-icon'>
                                    <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                                </span>
                                <span className='menu-text'>Dashboard</span>
                            </NavLink>
                        </li> */}
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/teacher/quizPool')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/teacher/quizPool'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Layout/Layout-4-blocks.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/teacher/questions')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/teacher/questions'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Shopping/Barcode-read.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.QUESTIONS'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}

						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/teacher/feedback')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/teacher/feedback'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl('/media/svg/icons/Media/Equalizer.svg')}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.FEEDBACK'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/leaderBoard')}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/leaderBoard'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Layout/Layout-4-blocks.svg',
								)}
							/>
						</span>
						<span className='menu-text'>
							<FormattedMessage id='NAV.LEADER_BOARD'></FormattedMessage>
						</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/student/subscription')}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/student/subscription'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Shopping/Barcode-read.svg',
								)}
							/>
						</span>
						<span className='menu-text'>
							Subscription
							{/* <FormattedMessage id='NAV.QUIZ_SETTING'></FormattedMessage> */}
						</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{profile === parseInt(process.env.REACT_APP_OWNER_PROFILE_ID) ? (
					<Fragment>
						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive('/admin/quizSetting')}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/admin/quizSetting'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Shopping/Barcode-read.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									<FormattedMessage id='NAV.QUIZ_SETTING'></FormattedMessage>
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
						{/*begin::1 Level*/}
						<li
							className={`menu-item ${getMenuItemActive(
								'/admin/subscription',
							)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/admin/subscription'>
								<span className='svg-icon menu-icon'>
									<SVG
										src={toAbsoluteUrl(
											'/media/svg/icons/Shopping/Barcode-read.svg',
										)}
									/>
								</span>
								<span className='menu-text'>
									Payment Settings
									{/* <FormattedMessage id='NAV.QUIZ_SETTING'></FormattedMessage> */}
								</span>
							</NavLink>
						</li>
						{/*end::1 Level*/}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}

				{/* Error Pages */}
				{/*begin::1 Level*/}
				{/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error"
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
              />
            </span>
            <span className="menu-text">Quiz Pool</span>
            <i className="menu-arrow" />
          </NavLink> */}
				{/* <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Quiz Pool</span>
                </span>
              </li> */}

				{/*begin::2 Level*/}
				{/* <li
                className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/Quizes/quiz?1">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Quiz 1</span>
                </NavLink>
              </li> */}
				{/*end::2 Level*/}
				{/* </ul>
          </div> 
        </li>*/}
				{/*end::1 Level*/}
			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
