import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import SVG from 'react-inlinesvg'
import Swal from 'sweetalert2'
import { Modal } from 'react-bootstrap'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../../../_metronic/_partials/controls'

import {
	// getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from '../../../../../../../_metronic/_helpers'

import { Button } from 'react-bootstrap'

import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'
import { Pagination } from '../../../../../../../_metronic/_partials/controls'
import { useQuizUIContext } from '../QuizUIContext'
import * as uiHelpers from '../QuizUIHelper'
import * as columnFormatters from './column-formatter'

import * as actions from '../../../../_redux/quiz/quizAction'
import { quizSlice } from '../../../../_redux/quiz/quizSlice'

import { QuizFilter } from '../quiz-filter/QuizFilter'

const quiz_slice = quizSlice.actions

const QuizTable = ({ intl }) => {
	const [showdeletemodal, showDeleteModal] = useState(false)
	const [idfordeletion, setIdForDeletion] = useState('')

	const quizUIContext = useQuizUIContext()
	const quizUIProps = useMemo(() => {
		return {
			ids: quizUIContext.ids,
			setIds: quizUIContext.setIds,
			queryParams: quizUIContext.queryParams,
			setQueryParams: quizUIContext.setQueryParams,
			newQuizButtonClick: quizUIContext.newQuizButtonClick,
			openEditQuizDialog: quizUIContext.openEditQuizDialog,
			openViewQuizPage: quizUIContext.openViewQuizPage,
			openViewStatsPage: quizUIContext.openViewStatsPage,
		}
	}, [quizUIContext])

	// Getting curret state of quiz list from store (Redux)
	const { currentState, user } = useSelector(
		(state) => ({
			currentState: state.quiz,
			user: state.auth.user,
		}),
		shallowEqual,
	)

	const { totalCount, entities, listLoading } = currentState

	const deleteQuiz = () => {
		dispatch(actions.deleteQuiz(idfordeletion))
	}

	const prepareDeleteQuiz = async (id, row) => {
		let allow_person = false

		//allow profile owner to delete any quiz
		//allow creator or the quiz to delete it
		if (user.profile === process.env.REACT_APP_OWNER_PROFILE_ID) {
			allow_person = true
		} else if (row.userid === user.userid) {
			allow_person = true
		}

		if (allow_person) {
			//check if this quiz is taken by some students, then dont allow deletion
			if (row.takenQuizes.length) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: `${intl.formatMessage({ id: 'QUIZ_DELETE_ERROR' })}`,
					confirmButtonColor: '#1BC5BD',
				})
			} else {
				//ask for confirmation
				showDeleteModal(true)
				setIdForDeletion(id)
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: `${intl.formatMessage({ id: 'PERMISSION_GENERAL_ERROR' })}`,
				confirmButtonColor: '#1BC5BD',
			})
		}
	}

	// quiz Redux state
	const dispatch = useDispatch()
	useEffect(() => {
		// clear selections list
		quizUIProps.setIds([])
		// server call by queryParams

		//initially make empty
		let totalCount = 0
		let entities = []
		dispatch(quiz_slice.quizesFetched({ totalCount, entities }))
		dispatch(actions.fetchQuizes(quizUIProps.queryParams, user.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quizUIProps.queryParams, dispatch])

	// Table columns
	const columns = [
		{
			dataField: 'quizId',
			text: 'ID',
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: 'quizName',
			text: `${intl.formatMessage({ id: 'QUIZ_NAME' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
			style: {
				minWidth: 130,
			},
		},
		{
			dataField: 'quizDescription',
			text: `${intl.formatMessage({ id: 'DESCRIPTION' })}`,
			style: {
				minWidth: 100,
				maxWidth: 250,
				display: 'block',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		},
		{
			dataField: 'takenCount',
			text: `${intl.formatMessage({ id: 'TAKEN_BY' })}`,
			sort: false,
			classes: 'text-center',
			headerClasses: 'text-center',
			formatter: (cell, row) => {
				if (row.hasOwnProperty('takenCount') && row.takenCount) {
					return row.takenCount + ' ' + intl.formatMessage({ id: 'STUDENTS' })
				}

				return '0 ' + intl.formatMessage({ id: 'STUDENTS' })
			},
		},
		{
			dataField: 'questionsCount',
			text: `${intl.formatMessage({ id: 'TOTAL_QUESTION' })}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'status',
			text: `${intl.formatMessage({ id: 'STATUS' })}`,
			classes: 'text-center',
			headerClasses: 'text-center',
		},
		{
			dataField: 'action',
			text: `${intl.formatMessage({ id: 'ACTIONS' })}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				prepareDeleteQuiz: prepareDeleteQuiz,
				openEditQuizDialog: quizUIProps.openEditQuizDialog,
				openViewQuizPage: quizUIProps.openViewQuizPage,
				openViewStatsPage: quizUIProps.openViewStatsPage,
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: 145,
			},
		},
	]

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: quizUIProps.queryParams.pageSize,
		page: quizUIProps.queryParams.pageNumber,
	}

	return (
		<Fragment>
			<Modal
				show={showdeletemodal}
				onHide={showDeleteModal}
				keyboard={false}
				backdrop='static'
				aria-labelledby='example-modal-sizes-title-lg'
			>
				<Modal.Header closeButton>
					<Modal.Title id='example-modal-sizes-title-lg'>
						<FormattedMessage id='DELETE_QUIZ'></FormattedMessage>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						<FormattedMessage id='ARE_YOU_SURE'></FormattedMessage>
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='danger'
						onClick={() => {
							deleteQuiz()
							showDeleteModal(!showdeletemodal)
						}}
					>
						<FormattedMessage id='DELETE'></FormattedMessage>
					</Button>
					<Button
						variant='secondary'
						onClick={() => showDeleteModal(!showdeletemodal)}
					>
						<FormattedMessage id='CLOSE'></FormattedMessage>
					</Button>
				</Modal.Footer>
			</Modal>

			<Card>
				<CardHeader
					title={<FormattedMessage id='NAV.QUIZ_POOL'></FormattedMessage>}
				>
					<CardHeaderToolbar>
						<Button
							variant='primary'
							size='sm'
							onClick={quizUIProps.newQuizButtonClick}
						>
							<span className='svg-icon svg-icon-md svg-icon-white'>
								<SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
							</span>
							Create Quiz
						</Button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<QuizFilter />
					<PaginationProvider pagination={paginationFactory(paginationOptions)}>
						{({ paginationProps, paginationTableProps }) => {
							return (
								<Pagination
									isLoading={listLoading}
									paginationProps={paginationProps}
									variant='primary'
								>
									<BootstrapTable
										wrapperClasses='table-responsive'
										bordered={false}
										classes='table table-head-custom table-vertical-center text-dark'
										bootstrap4
										remote
										keyField='quizId'
										data={entities === null ? [] : entities}
										columns={columns}
										defaultSorted={uiHelpers.defaultSorted}
										onTableChange={getHandlerTableChange(
											quizUIProps.setQueryParams,
										)}
										selectRow={{ mode: 'radio', hideSelectColumn: true }}
										{...paginationTableProps}
									>
										<PleaseWaitMessage entities={entities} />
										<NoRecordsFoundMessage entities={entities} />
									</BootstrapTable>
								</Pagination>
							)
						}}
					</PaginationProvider>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default injectIntl(QuizTable)
